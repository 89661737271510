<template>
  <section id="daftar-konfirmasi-rekomendasi">
    <b-row>
      <b-col
        cols="12"
      >
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>

        <b-card>
          <b-row>
            <b-col
              v-if="userData.group_id === 10"
              class="mb-1"
            >
              <b-button
                variant="outline-primary"
                class="p-50"
                @click="downloadFile"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="12"
                />
                Download .xlsx
              </b-button>
            </b-col>
            <b-col
              v-if="userData.group_id > 59 && userData.group_id < 70"
              class="mb-1 text-right"
            >
              <b-button
                variant="outline-primary"
                class="p-50"
                @click="openBeritaAcara"
              >
                <feather-icon
                  icon="MailIcon"
                  size="12"
                />
                Buat Berita Acara
              </b-button>
            </b-col>
          </b-row>
          <!-- search input -->
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_data_instansi"
                  v-model="master_data_instansi"
                  required
                  name="master_data_instansi"
                  :options="referenceData.ref_instansi"
                  placeholder="Daftar Instansi"
                  label="name"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_jenis_pengadaan_id"
                  v-model="master_jenis_pengadaan_id"
                  required
                  name="master_jenis_pengadaan_id"
                  :options="referenceData.ref_jenis_pengadaan"
                  placeholder="Kriteria Belanja"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_sumber_dana_id"
                  v-model="master_sumber_dana_id"
                  name="master_sumber_dana_id"
                  :options="referenceData.ref_sumber_dana"
                  placeholder="Sumber Dana"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="outline-secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length,
              mode: 'pages',
              setCurrentPage:selectPage,
              perPageDropdown: [15, 30, 60, 100],
              dropdownAllowAll: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'anggaran'">
                {{ Number(props.row.anggaran).toLocaleString() }}
              </span>
              <span v-else-if="props.column.field === 'nama_kegiatan'">
                <feather-icon
                  v-if="props.row.prioritas_nasional === 1"
                  class="text-warning"
                  icon="StarIcon"
                  size="14"
                />
                {{ props.row.nama_kegiatan }}
              </span>
              <span v-else-if="props.column.field === 'nama'">
                <b-badge
                  variant="light-info"
                  class="text-wrap mb-50"
                >
                  {{ props.row.domain }}
                </b-badge>
                <span class="d-block">
                  {{ props.row.nama }}
                </span>
              </span>
              <span v-else-if="props.column.field === 'last_update'">
                {{ props.row.last_update | formatDate }}
              </span>
              <span v-else-if="props.column.field === 'wait_time'">
                {{ props.row.wait_time | formatDate }}
              </span>
              <!-- Approval Asdep -->
              <div
                v-else-if="props.column.field === 'asdep_confirm'"
                class="d-flex justify-content-center"
              >
                <b-form-checkbox
                  v-if="userData.group_id === 53 && props.row.status === 'Selesai Konfirmasi OPD'"
                  v-model="props.row.asdep_confirm"
                  :name="`approve-asdep-${props.row.id}`"
                  switch
                  inline
                  @change="saveApprovalDirektur('asdep', props.row)"
                />
                <template
                  v-else
                >
                  <span
                    v-if="props.row.asdep_confirm"
                    class="text-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="20"
                    />
                  </span>
                  <span
                    v-else
                    class="text-primary"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </span>
                </template>
              </div>
              <!-- Approval Takel -->
              <div
                v-else-if="props.column.field === 'takel_confirm'"
                class="d-flex justify-content-center"
              >
                <b-form-checkbox
                  v-if="userData.group_id === 51 && props.row.status === 'Selesai Konfirmasi OPD'"
                  v-model="props.row.takel_confirm"
                  :name="`approve-takel-${props.row.id}`"
                  switch
                  inline
                  @change="saveApprovalDirektur('takel', props.row)"
                />
                <template
                  v-else
                >
                  <span
                    v-if="props.row.takel_confirm"
                    class="text-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="20"
                    />
                  </span>
                  <span
                    v-else
                    class="text-primary"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </span>
                </template>
              </div>
              <!-- Approval LAIP -->
              <div
                v-else-if="props.column.field === 'laip_confirm'"
                class="d-flex justify-content-center"
              >
                <b-form-checkbox
                  v-if="userData.group_id === 52 && props.row.status === 'Selesai Konfirmasi OPD'"
                  v-model="props.row.laip_confirm"
                  :name="`approve-laip-${props.row.id}`"
                  switch
                  inline
                  @change="saveApprovalDirektur('laip', props.row)"
                />
                <template
                  v-else
                >
                  <span
                    v-if="props.row.laip_confirm"
                    class="text-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="20"
                    />
                  </span>
                  <span
                    v-else
                    class="text-primary"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </span>
                </template>
              </div>
              <span v-else-if="props.column.field === 'status'">
                <b-button
                  v-if="props.row.status !== 'Menunggu Tanggapan OPD'"
                  v-b-tooltip.hover
                  :variant="statusVariant(props.row.status).class"
                  size="sm"
                  style="max-width:120px"
                  title="Lihat Tanggapan OPD"
                  @click="openKonfirmasi(props.row)"
                >
                  {{ statusVariant(props.row.status).title }}
                </b-button>
                <b-button
                  v-else
                  :variant="statusVariant(props.row.status).class"
                  size="sm"
                  style="max-width:120px"
                >
                  {{ statusVariant(props.row.status).title }}
                </b-button>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field == 'action'">
                <b-button
                  v-if="props.row.status === 'Menunggu Pembahasan Dengan OPD' && userData.group_id > 59 && userData.group_id < 70"
                  v-b-tooltip.hover
                  variant="info"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Input Hasil Konfirmasi Rekomendasi"
                  @click.prevent="inputHasilPembahasan(props.row)"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="12"
                  /></b-button>
                <b-button
                  v-else-if="props.row.status !== 'Menunggu Pembahasan Dengan OPD' && props.row.status !== 'Menunggu Tanggapan OPD' && props.row.status_pembahasan !== 'Menunggu Pembahasan Dengan OPD'"
                  v-b-tooltip.hover
                  variant="success"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Hasil Pembahasan Konfirmasi Rekomendasi"
                  @click="openHasilKonfirmasi(props.row)"
                >
                  <feather-icon
                    icon="ListIcon"
                    size="12"
                  />
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  :to="{ name: 'kegiatan-detail', params: { id: props.row.data_utama_id } }"
                  variant="secondary"
                  size="sm"
                  class="mr-25 mb-25"
                  target="_blank"
                  title="Detail Pengadaan"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    size="12"
                  /></b-button>

              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
      <!-- post -->

    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-tanggapan"
      ref="modal-tanggapan"
      hide-footer
      centered
      size="lg"
      :title="modalTitle"
    >
      <summary-tanggapan
        :component-id="tanggapan.componentId"
        :detail-program="tanggapan.detailProgram"
        :step-rekomendasi="tanggapan.stepRekomendasi"
        @view-file="viewFile"
      />
    </b-modal>

    <b-modal
      id="modal-hasil-rekomendasi"
      ref="modal-hasil-rekomendasi"
      hide-footer
      centered
      size="lg"
      title="Rekapitulasi Hasil Pembahasan Konfirmasi Rekomendasi"
    >
      <summary-rekomendasi
        :document="listRekomendasi.document"
        :keterangan="listRekomendasi.keterangan"
        :rekap-lanjut="listRekomendasi.continue"
        :rekap-tidak="listRekomendasi.postpone"
        @view-file="viewFile"
      />
    </b-modal>

    <b-modal
      id="modal-berita-acara"
      ref="modal-berita-acara"
      title="Buat Berita Acara"
      hide-footer
      centered
      no-close-on-backdrop
      size="md"
    >
      <b-card-text>
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>
        <validation-observer ref="newBeritaAcara">
          <b-form @submit.prevent="checkBeritaAcara">
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Pilih Instansi"
                  label-for="vi-title"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Instansi"
                    rules="required"
                  >
                    <v-select
                      id="vi-ba_instansi"
                      v-model="ba_instansi"
                      required
                      name="ba_instansi"
                      :options="referenceData.ref_instansi"
                      placeholder="Daftar Instansi"
                      label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Kriteria Belanja"
                  label-for="vi-title"
                >
                  <b-form-radio
                    v-model="ba_jenis"
                    name="jenis_ba"
                    value="1"
                    required
                  >
                    Data
                  </b-form-radio>
                  <b-form-radio
                    v-model="ba_jenis"
                    name="jenis_ba"
                    value="2"
                    required
                  >
                    Lainnya (Aplikasi, Infrastruktur, Non-Teknis)
                  </b-form-radio>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mb-1"
              >
                <b-button
                  type="submit"
                  variant="primary"
                >
                  Buat Berita Acara
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </section>

</template>

<script>
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment-business-time'
import vSelect from 'vue-select'
import {
  BFormCheckbox, BRow, BBadge, BButton, BCol, BCard,
  BFormGroup, BFormInput, VBTooltip, VBModal, BFormRadio,
  BInputGroup, BInputGroupAppend, BCardText, BAlert, BForm,
} from 'bootstrap-vue'
import VuePdfApp from 'vue-pdf-app'

import stColors from '@/vars/statusColors'
import tableColumns from '@/vars/konfirmasiColumns'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'

import SummaryTanggapan from '../rekomendasi/SummaryTanggapan.vue'
import SummaryRekomendasi from '../rekomendasi/SummaryRekomendasi.vue'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BAlert,
    BFormCheckbox,
    BFormRadio,
    BButton,
    BCard,
    BRow,
    BCol,
    BForm,
    VueGoodTable,
    BBadge,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    vSelect,
    SummaryTanggapan,
    SummaryRekomendasi,
    VuePdfApp,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const {
      activityColors, activityColorsNew, clearanceColors, statusColors, verificationColors,
    } = stColors()
    const { newColumns, subColumns } = tableColumns()

    const yearBudget = localStorage.getItem('tahunAnggaran')
    const baseColumns = newColumns
    const serverParams = {
      tahun_anggaran: yearBudget,
      sort_by: [
        {
          column: 'last_update',
          asc_desc: 'asc',
        },
      ],
      start: 0,
      length: 30,
    }

    return {
      yearBudget,
      activityColors,
      activityColorsNew,
      clearanceColors,
      statusColors,
      verificationColors,
      baseColumns,
      subColumns,
      serverParams,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 69) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      required,
      fileDoc: '',
      alertText: '',
      userData: getUserData(),
      referenceData: {
        ref_instansi: [],
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_sumber_dana: [],
      },
      modalTitle: 'Tanggapan OPD',
      isLoading: false,
      totalRecords: 0,
      selectPage: 1,
      columns: [],
      rows: [],
      searchTerm: '',
      tanggapan: {},
      listRekomendasi: {},
      master_data_instansi: '',
      master_jenis_belanja_id: '',
      master_jenis_pengadaan_id: '',
      master_sumber_dana_id: '',
      prefix: '',
      nama_kegiatan: '',
      nama_app_infra: '',
      errorStat: false,
      errorMsg: '',
      ba_instansi: '',
      ba_jenis: '',
    }
  },
  created() {
    this.setupTanggal()
  },
  mounted() {
    if (localStorage.getItem('master_instansi_id')) {
      this.master_data_instansi = JSON.parse(localStorage.getItem('master_instansi_id'))
    }
    if (localStorage.getItem('master_jenis_belanja_id')) {
      this.master_jenis_belanja_id = JSON.parse(localStorage.getItem('master_jenis_belanja_id'))
    }
    if (localStorage.getItem('master_jenis_pengadaan_id')) {
      this.master_jenis_pengadaan_id = JSON.parse(localStorage.getItem('master_jenis_pengadaan_id'))
    }
    if (localStorage.getItem('master_sumber_dana_id')) {
      this.master_sumber_dana_id = JSON.parse(localStorage.getItem('master_sumber_dana_id'))
    }
    if (localStorage.getItem('instansi')) {
      this.prefix = localStorage.getItem('instansi')
      this.baseColumns[0].filterOptions.filterValue = this.prefix
    }
    if (localStorage.getItem('nama_kegiatan')) {
      this.nama_kegiatan = localStorage.getItem('nama_kegiatan')
      this.baseColumns[1].filterOptions.filterValue = this.nama_kegiatan
    }
    if (localStorage.getItem('nama_app_infra')) {
      this.nama_app_infra = localStorage.getItem('nama_app_infra')
      this.baseColumns[2].filterOptions.filterValue = this.nama_app_infra
    }
    if (localStorage.getItem('searchTerm')) {
      this.searchTerm = localStorage.getItem('searchTerm')
    }
    this.columns = this.baseColumns
    const { page, currentPage, currentPerPage } = store.state.app
    if (page === 'konfirmReko' && currentPage && currentPerPage) {
      this.selectPage = currentPage
      this.serverParams.length = currentPerPage
      this.updateParams({ start: (currentPage - 1) * this.serverParams.length })
    }
    this.doFilter()
    store.commit('app/SET_PAGE', 'konfirmReko')
    this.getReference()
  },
  methods: {
    inputHasilPembahasan(appData) {
      const params = {
        type: 'konfirmasi',
        utama: appData.data_utama_id,
        id: appData.jenis_pengadaan_id,
      }
      if (appData.aplikasi_id) {
        params.data = appData.aplikasi_id
      } else if (appData.infrastruktur_id) {
        params.data = appData.infrastruktur_id
      } else if (appData.data_informasi_id) {
        params.data = appData.data_informasi_id
      } else if (appData.non_teknis_id) {
        params.data = appData.non_teknis_id
      }
      this.$router.replace({ name: 'rekomendasi-item', params })
    },
    downloadFile() {
      const params = this.serverParams
      this.$http.post(`/pk/download-excel/${this.yearBudget}?token=${localStorage.getItem('userToken')}`, params)
        .then(res => {
          if (res.data.status === 'success') {
            const link = document.createElement('a')
            link.href = `${process.env.VUE_APP_API_URL}/document/excel/download/${res.data.data.filename}?token=${localStorage.getItem('userToken')}`
            link.click()
          }
          return true
        })
    },
    setupTanggal() {
      let holidays = []
      if (localStorage.getItem('holidays')) {
        holidays = JSON.parse(localStorage.getItem('holidays'))
      } else {
        this.$http.get('/tanggalan/holidays')
          .then(res => {
            const liburAll = res.data.data
            const libur = []

            Object.keys(liburAll).map(data => {
              libur.push(data)
              return data
            })
            holidays = libur
            localStorage.setItem('holidays', JSON.stringify(libur))
          })
      }
      moment.updateLocale('en', {
        holidays,
      })
    },
    tableSearch() {
      localStorage.setItem('searchTerm', this.searchTerm)
      this.updateParams({ keyword: this.searchTerm })
      this.doFilter()
    },
    searchReset() {
      this.searchTerm = ''
      localStorage.removeItem('searchTerm')
      this.updateParams({ keyword: '' })
      this.doFilter()
    },
    getReference() {
      this.$http.get('/recommendation/list-references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data.references
          }
        })
    },
    statusVariant(status) {
      if (this.statusColors[status]) {
        return this.statusColors[status]
      }
      return {
        title: status,
        class: 'warning',
      }
    },
    globalFilter() {
      const params = []
      if (this.master_data_instansi) {
        params.push({
          column: 'instansi_id',
          value: String(this.master_data_instansi.instansi_id),
        })
        localStorage.setItem('master_instansi_id', JSON.stringify(this.master_data_instansi))
      } else {
        localStorage.removeItem('master_instansi_id')
      }
      if (this.master_jenis_belanja_id) {
        params.push({
          column: 'jenis_belanja_id',
          value: String(this.master_jenis_belanja_id.jenis_belanja_id),
        })
        localStorage.setItem('master_jenis_belanja_id', JSON.stringify(this.master_jenis_belanja_id))
      } else {
        localStorage.removeItem('master_jenis_belanja_id')
      }
      if (this.master_jenis_pengadaan_id) {
        params.push({
          column: 'jenis_pengadaan_id',
          value: String(this.master_jenis_pengadaan_id.jenis_pengadaan_id),
        })
        localStorage.setItem('master_jenis_pengadaan_id', JSON.stringify(this.master_jenis_pengadaan_id))
      } else {
        localStorage.removeItem('master_jenis_pengadaan_id')
      }
      if (this.master_sumber_dana_id) {
        params.push({
          column: 'sumber_dana_id',
          value: String(this.master_sumber_dana_id.sumber_dana_id),
        })
        localStorage.setItem('master_sumber_dana_id', JSON.stringify(this.master_sumber_dana_id))
      } else {
        localStorage.removeItem('master_sumber_dana_id')
      }
      return params
    },
    doFilter() {
      const params = this.globalFilter()
      if (this.prefix !== '') {
        params.push({
          column: 'instansi',
          value: String(this.prefix),
        })
        localStorage.setItem('instansi', this.prefix)
      } else {
        localStorage.removeItem('instansi')
      }
      if (this.nama_kegiatan !== '') {
        params.push({
          column: 'nama_kegiatan',
          value: this.nama_kegiatan,
        })
        localStorage.setItem('nama_kegiatan', this.nama_kegiatan)
      } else {
        localStorage.removeItem('nama_kegiatan')
      }
      if (this.nama_app_infra !== '') {
        params.push({
          column: 'nama',
          value: this.nama_app_infra,
        })
        localStorage.setItem('nama_app_infra', this.nama_app_infra)
      } else {
        localStorage.removeItem('nama_app_infra')
      }
      if (this.searchTerm !== '') {
        this.updateParams({ keyword: this.searchTerm })
        localStorage.setItem('searchTerm', this.searchTerm)
      } else {
        this.updateParams({ keyword: '' })
        localStorage.removeItem('searchTerm')
      }
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      store.commit('app/CURRENT_PAGE', params.currentPage)
      this.selectPage = store.state.app.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      store.commit('app/CURRENT_PER_PAGE', params.currentPerPage)
      this.updateParams({ length: params.currentPerPage, start: (this.selectPage - 1) * params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({ start: 0 })
      params.map(param => {
        let columName = param.field
        if (param.field === 'instansi') {
          columName = 'instansi'
        } else if (param.field === 'nama') {
          columName = 'nama'
        }
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: columName,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams({ start: 0 })
      const filterz = this.globalFilter()
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry

        let columName = key
        if (key === 'instansi') {
          columName = 'instansi'
        } else if (key === 'nama') {
          columName = 'nama'
        }
        this[columName] = valuez
        if (valuez !== '') {
          filterz.push({
            column: columName,
            value: valuez,
          })
          localStorage.setItem(columName, valuez)
        } else {
          localStorage.removeItem(columName)
        }
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post(`/pk/list?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          this.rows = []
          if (res.data.status === 'success') {
            this.errorStat = false
            res.data.data.data.map(result => {
              // ketua tim , is_continue 1 : continue, 0 : belum, -1 : tidak dilanjutkan
              // direktur, is_continue 1 : continue, 0 : tidak dilanjutkan
              const rekolist = result
              rekolist.asdep_confirm = (result.asdep_confirm === 1)
              rekolist.takel_confirm = (result.takel_confirm === 1)
              rekolist.laip_confirm = (result.laip_confirm === 1)
              if (this.userData.group_id > 69 && this.userData.group_id < 80) {
                if (result.is_continue === 1) {
                  rekolist.status_pembahasan = 'Dilanjutkan'
                } else if (result.is_continue === 0) {
                  rekolist.status_pembahasan = 'Menunggu Pembahasan'
                } else {
                  rekolist.status_pembahasan = 'Tidak Dilanjutkan'
                }
              }
              if (result.domain === 'APLIKASI') {
                rekolist.id = result.aplikasi_id
              } else if (result.domain === 'INFRASTRUKTUR') {
                rekolist.id = result.infrastruktur_id
              }
              if (result.aplikasi_id) {
                rekolist.id = result.aplikasi_id
              }
              if (result.infrastruktur_id) {
                rekolist.id = result.infrastruktur_id
              }
              if (result.data_informasi_id) {
                rekolist.id = result.data_informasi_id
              }
              if (result.non_teknis_id) {
                rekolist.id = result.non_teknis_id
              }
              const lastUpdate = moment(rekolist.last_update).utc()
              rekolist.wait_time = lastUpdate.addWorkingTime(5, 'days')
              this.rows.push(rekolist)
              return true
            })
            this.totalRecords = res.data.data.total_rows
          } else {
            this.rows = []
            this.totalRecords = 0
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          this.totalRecords = 0
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    saveApprovalDirektur(who, appData) {
      const fieldName = `approve-${who}-${appData.id}`
      const field = document.querySelector(`input[name=${fieldName}]:checked`)

      if (field.checked) {
        const verif = {
          dispute_id: appData.dispute_id,
          data_utama_id: appData.data_utama_id,
          domain: appData.domain,
        }
        if (appData.domain === 'APLIKASI') {
          verif.aplikasi_id = appData.aplikasi_id
        } else if (appData.domain === 'INFRASTRUKTUR') {
          verif.infrastruktur_id = appData.infrastruktur_id
        }
        this.$http.post('/pk/confirm', verif, {
          params: {
            token: localStorage.getItem('userToken'),
          },
        })
          .then(res => {
            if (res.data) {
              this.dataSaved = true

              setTimeout(() => {
                this.dataSaved = false
              }, 1000)
            }
          })
      }
    },
    openKonfirmasi(appData) {
      this.modalTitle = 'Tanggapan OPD'
      this.tanggapan = {
        componentId: 0,
        detailProgram: appData,
        stepRekomendasi: appData.domain.toLowerCase(),
      }
      if (appData.domain === 'APLIKASI') {
        this.tanggapan.componentId = appData.aplikasi_id
      } else if (appData.domain === 'INFRASTRUKTUR') {
        this.tanggapan.componentId = appData.infrastruktur_id
      }
      if (appData.aplikasi_id) {
        this.tanggapan.stepRekomendasi = 'app'
        this.tanggapan.componentId = appData.aplikasi_id
      } else if (appData.infrastruktur_id) {
        this.tanggapan.stepRekomendasi = 'infra'
        this.tanggapan.componentId = appData.infrastruktur_id
      } else if (appData.data_informasi_id) {
        this.tanggapan.stepRekomendasi = 'data'
        this.tanggapan.componentId = appData.data_informasi_id
      } else if (appData.non_teknis_id) {
        this.tanggapan.stepRekomendasi = 'non-teknis'
        this.tanggapan.componentId = appData.non_teknis_id
      }
      this.$refs['modal-tanggapan'].show()
    },
    openHasilKonfirmasi(appData) {
      const params = {
        token: localStorage.getItem('userToken'),
        data_utama_id: appData.data_utama_id,
      }
      if (appData.domain === 'APLIKASI') {
        params.aplikasi_id = appData.aplikasi_id
        params.type = 'app'
      } else if (appData.domain === 'INFRASTRUKTUR') {
        params.infrastruktur_id = appData.infrastruktur_id
        params.type = 'infra'
      }
      if (appData.aplikasi_id) {
        params.type = 'app'
        params.aplikasi_id = appData.aplikasi_id
      } else if (appData.infrastruktur_id) {
        params.type = 'infra'
        params.infrastruktur_id = appData.infrastruktur_id
      } else if (appData.data_informasi_id) {
        params.type = 'data'
        params.data_informasi_id = appData.data_informasi_id
      } else if (appData.non_teknis_id) {
        params.type = 'non-teknis'
        params.non_teknis_id = appData.non_teknis_id
      }

      this.$http.get('/recommendation/get-rekomendasi-sementara', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listRekomendasi = res.data.data
            this.$refs['modal-hasil-rekomendasi'].show()
          }
        })
    },
    viewFile(data) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    openBeritaAcara() {
      this.$refs['modal-berita-acara'].show()
    },
    checkBeritaAcara() {
      this.$refs.newBeritaAcara.validate().then(success => {
        if (success) {
          this.$router.push({ name: 'buat-berita-acara-rekomendasi', params: { id: this.ba_instansi.instansi_id, jenis: this.ba_jenis } })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
