export default function tableColumns() {
  const oldColumns = [
    {
      thClass: 'text-center',
      label: 'Pemda',
      field: 'instansi',
      width: '175px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Instansi',
      },
    },
    {
      thClass: 'text-center',
      label: 'Nama Kegiatan',
      field: 'nama_kegiatan',
      width: '175px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Program',
      },
    },
    {
      thClass: 'text-center',
      label: 'Aplikasi / Infrastruktur',
      field: 'nama',
      width: '175px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Aplikasi / Infrastruktur',
      },
    },
    {
      thClass: 'text-center',
      label: 'Total Anggaran',
      field: 'anggaran',
      type: 'number',
    },
    {
      thClass: 'text-center',
      label: 'Perubahan Terakhir',
      field: 'last_update',
    },
    {
      thClass: 'text-center',
      label: 'Status',
      width: '140px',
      field: 'status',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Batas Waktu',
      field: 'wait_time',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Action',
      field: 'action',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Asdep SPBE',
      field: 'asdep_confirm',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Dir. Takel',
      field: 'takel_confirm',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Dir. LAIP',
      field: 'laip_confirm',
      sortable: false,
    },
  ]

  const newColumns = [
    {
      thClass: 'text-center',
      label: 'Pemda',
      field: 'instansi',
      width: '175px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Instansi',
      },
    },
    {
      thClass: 'text-center',
      label: 'Nama Kegiatan',
      field: 'nama_kegiatan',
      width: '175px',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Program',
      },
    },
    {
      thClass: 'text-center',
      label: 'Item Belanja',
      field: 'nama',
      width: '175px',
      filterOptions: {
        enabled: true,
        placeholder: 'Item Belanja',
      },
    },
    {
      thClass: 'text-center',
      label: 'Total Anggaran',
      field: 'anggaran',
      type: 'number',
    },
    {
      thClass: 'text-center',
      label: 'Tanggal Permohonan',
      field: 'last_update',
    },
    {
      thClass: 'text-center',
      label: 'Status',
      width: '120px',
      field: 'status',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Batas Waktu',
      field: 'wait_time',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Action',
      field: 'action',
      sortable: false,
    },
  ]

  const subColumns = [
    {
      thClass: 'text-center',
      label: 'Domain',
      field: 'clearance_app_id',
    },
    {
      thClass: 'text-center',
      label: 'Nama',
      field: 'nama',
    },
    {
      thClass: 'text-center',
      label: 'Total Anggaran',
      type: 'number',
      field: 'app_area_id',
    },
    {
      thClass: 'text-center',
      label: 'Jenis',
      field: 'app_teknis_status_id',
    },
    {
      thClass: 'text-center',
      label: 'Status',
      field: 'teknis_status',
    },
  ]

  return {
    oldColumns,
    newColumns,
    subColumns,
  }
}
